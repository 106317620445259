import request from "@/utils/request";

// 增加阅读分类
export function addReadingType(data) {
  return request({
      url: '/admin/french/readAddCate',
      method: 'post',
      data
  })
}

// 修改阅读分类
export function editReadingType(data) {
  return request({
      url: '/admin/french/readUpdateCate',
      method: 'post',
      data
  })
}

//获取阅读分类
export function getReadingType(params) {
  return request({
      url: '/admin/french/readgetCateList',
      method: 'get',
      params
  })
}

// 获取上传COS签名
export function getReadCosToken(params) {
  return request({
      url: '/admin/french/readCosToken',
      method: 'get',
      params
  })
}

//添加阅读的单词
export function addReadingContentWords(data) {
  return request({
      url: '/admin/french/readAddContentWords',
      method: 'post',
      data
  })
}

//添加阅读的文章内容
export function addReadingContent(data) {
  return request({
      url: '/admin/french/readAddContent',
      method: 'post',
      data
  })
}

//修改阅读的内容
export function updateReadingContent(data) {
  return request({
      url: '/admin/french/readUpdateContent',
      method: 'post',
      data
  })
}

//获取阅读内容列表
export function getReadingContentList(params) {
  return request({
      url: '/admin/french/readGetContentList',
      method: 'get',
      params
  })
}

//获取阅读详情
export function getReadingContentDetail(params) {
  return request({
      url: '/admin/french/readContentDetail',
      method: 'get',
      params
  })
}

//获取阅读详情
export function getReadContentRecommendIndex(params) {
  return request({
      url: '/admin/french/readContentRecommendIndex',
      method: 'get',
      params
  })
}