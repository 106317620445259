<template>
  <div>
    <el-card>
        <el-form ref="form" :inline="true">
         <el-form-item label="类型">
          <el-select
            v-model="queryForm.cate_id"
            placeholder="请选择"
            clearable
            width="100px"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in readingTypeList"
              :key="item.id"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              type="datetimerange"
              v-model="times"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="首页推荐/全部">
            <el-select
              v-model="queryForm.recommend_index"
              placeholder="请选择"
              clearable
              width="100px"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in recommendList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toQuery" icon="el-icon-search"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item class="generate">
            <el-button type="primary" @click="toType"
              >文章分类</el-button
            >
          </el-form-item>
          <el-form-item class="generate">
            <el-button type="primary" @click="toAdd"
              >新增文章</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          :data="contentList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >

          <el-table-column
            prop="id"
            label="编号"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column label="标题" prop="title" align="center"></el-table-column>
          <el-table-column label="封面" prop="thumb_url" align="center" #default="scope">
            <div class="radio_thumb_img">
              <img :src="scope.row.cover" alt="" srcset="" style="width:82px;height:112px">
            </div>
          </el-table-column>
          <el-table-column label="分类" prop="cate_name" align="center"></el-table-column>
          <el-table-column label="时间" align="center">
            <template slot-scope="scope">
              {{scope.row.create_time}}
            </template>
          </el-table-column>
          <el-table-column label="是否推荐到首页" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.recommend_index == 1">
                已推荐
              </el-tag>
              <el-tag type="danger" v-else
                >未推荐
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" >
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editContent(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="success"
                icon="el-icon-top"
                circle
                @click="toIndex(scope.row.id,scope.row.recommend_index)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      
      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.page"
          :page-sizes="[3, 5, 8, 10]"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          algin:="center"
        ></el-pagination>
     </div>

    <!-- 新增文章弹窗 -->
    <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="文章标题" label-width="100px" v-if="addForm.article">
            <el-input
              type="text"
              v-model="addForm.article.title"
              placeholder="请输入标题"
            />
        </el-form-item>
        <el-form-item el-form-item label="文章作者" label-width="100px" v-if="addForm.article">
            <el-input
              type="text"
              v-model="addForm.article.author"
              placeholder="请输入文章作者"
            />
        </el-form-item>
        <el-form-item el-form-item label="文章内容" label-width="100px" v-if="addForm.article">
          <el-input type="textarea" :rows="4" v-model="addForm.article.content" style="width:93 %;" placeholder="请输入文章内容"/>
        </el-form-item>
        <el-form-item el-form-item label="文章翻译" label-width="100px" v-if="addForm.article">
          <el-input type="textarea" :rows="4" v-model="addForm.article.translation" style="width:93 %;" placeholder="请输入文章翻译"/>
        </el-form-item>
        <el-form-item el-form-item label="分类" label-width="100px" v-if="addForm.article">
          <el-select
            v-model="addForm.article.cate_id"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in readingTypeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="封面" label-width="100px" v-if="addForm.article">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload"
                style="width: 82px;height:112px;"
              >
                  <img v-if="thumbSrc" style="width: 100%;height:100%" :src="thumbSrc" class="avatar"/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传封面图尺寸为246 X 336且不大于10MB</div>
            </el-row>
        </el-form-item>

        <div style="display:flex;justify-content:space-between;">
            <el-form-item el-form-item label="单词" label-width="100px">  </el-form-item>
            <el-button @click.prevent="addDomain()" style="width:90px;height:40px;">再加一个</el-button>
        </div>

        <el-form-item
            label-width="100px"
            v-for="(domain, index) in addForm.word"
            :label="'单词'+Number(index+1)"       
            :key="domain.index"
            :prop="'word.' + index + '.words'"
            v-if="addForm.word"
        >
            <el-input v-model="domain.word" style="width:93%;margin-bottom:10px;margin-right:10px" placeholder="请输入单词"/>
            <el-button size="small" type="danger" icon="el-icon-delete" circle @click.prevent="removeDomain(domain)"></el-button>
            <el-input type="textarea" :rows="4" v-model="domain.syntax" style="width:93 %;" placeholder="请输入注释"/>
            <div v-if="addForm.word[index].voice">
                <audio
                  :src="addForm.word[index].voice"
                  style="cursor: pointer;outline: none;"
                  controls="controls" 
                  ref="audioPlay"
                ></audio>
            </div>
            <el-button type="primary" @click="selectAudio(index)"
              >{{isAdd=='add'?'上传音频':'修改音频'}}</el-button
            >
            <span class="file-name">{{ addForm.word[index].fileAudioName }}</span>
            <el-progress :percentage="addForm.word[index].progress" v-if="addForm.word[index].progress"></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audio"
              @change="selectAudioFiles"
              accept="audio/*"
            />
            <el-input type="textarea" :rows="1" v-model="domain.note" style="width:93 %;" placeholder="请输入备注"/>
        </el-form-item>
       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 文章分类弹窗 -->
    <el-dialog title="文章分类列表" :visible.sync="typeDialog" :center=true width="70%" @close="typeDialogClose" :close-on-click-modal=false>
      <el-form ref="form" :inline="true">
          <el-form-item label="添加分类">
            <el-input
              placeholder="请输入分类标题"
              v-model="editReadingType"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addType">添加</el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="readingTypeList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column label="分类名称" prop="name" align="center"></el-table-column>
          <el-table-column label="操作" align="center" >
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editType(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeTask(scope.row.id)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
    </el-dialog>
    <!-- 修改分类弹窗 -->
    <el-dialog :title="editTypeName" :visible.sync="editTypeDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="分类标题" label-width="100px">
            <el-input
              type="text"
              v-model="nowTypeName"
            />
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" @click="editTypeNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  addReadingType,
  getReadingType,
  editReadingType,
  getReadCosToken,
  addReadingContentWords,
  addReadingContent,
  updateReadingContent,
  getReadingContentList,
  getReadingContentDetail,
  getReadContentRecommendIndex
} from "@/api/French/french_reading";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      readingFunctionList: ["文章列表","文章分类"],
      tabs: 0,
      type: "",
      editReadingType:"",
      readingTypeList: [],
      recommendList: [{id:1,name:"首页推荐"},{id:0,name:"全部"}],
      queryForm: {
        cate_id: "",
        page: 1,
        size: 10,
        start_time: null,
        end_time: null,
        recommend_index: null,
      },
      syntaxType: [],
      syntaxList: [],
      // gradeList: [],
      total: 0,
      addDialog: false,
      addTypeDialog: false,
      editTypeDialog: false,
      typeDialog: false,
      times: null,
      contentList: [],
      addForm: {
        article: {
          cover: "",
          cate_id: null,
          title: "",
          author: "",
          translation: "",
          content: "",
          words_id: null,
          // recommend_index: 0,
        },
        // wordArr:{
          word: [{
            word: '',
            syntax: '',
            voice: "",
            note: "",
            progress: 0,
            fileAudioName: ""
            // key: 1
          }],
        // },
      },
      // progress: 0,
      // fileAudioName: "",
      clickAudio: null,
      thumbSrc: "",
      addtitle: "",
      addTypeTitle:"",
      editTypeName:"",
      nowTypeName:"",
      typeInfo:{},
      isAdd: "add",
      lineData: [],
      isCascader: true,
      godown: true,
    };
  },

  created() {
    // this.getSyntaxType();
    // this.getSyntaxList();
    this.type = "文章列表";
    this.getType();
    this.getContentList();
  },
  methods: {
    //添加阅读分类
    addType(){
      let typeName = this.editReadingType;
      console.log(typeName)
      addReadingType({name:typeName}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加分类成功！");
        this.editReadingType = "";
        this.getType();
      });
    },
    //获取阅读分类
    getType(){
      getReadingType().then((res) => {
        console.log(res.data.data)
        this.readingTypeList = res.data.data;
        // console.log(this.readingTypeList)
        this.getContentList();
      });
    },
    //修改阅读分类弹窗
    editType(typeName){
      this.typeInfo = typeName;
      this.nowTypeName = typeName.name;
      this.editTypeName = "修改分类名称";
      this.editTypeDialog = true;
    },
    //修改阅读分类
    editTypeNow(){
      this.typeInfo.name = this.nowTypeName;
      editReadingType(this.typeInfo).then((res)=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.editTypeDialog = false;
        this.$message.success("修改分类成功!");
      })
    },
    //增加一行单词
    addDomain() {
      this.addForm.word.push({
        word: '',
        voice: '',
        syntax: '',
        note: '',
        // key: Date.now()
      });
    },
    //删除这行单词
    removeDomain(item) {
      var index = this.addForm.word.indexOf(item)
      if (index !== -1) {
        this.addForm.word.splice(index, 1)
      }
    },
    // 日语APP语法分类
    getSyntaxType() {
      SyntaxType().then((res) => {
        // console.log(res);
        this.gradeList = res.data.grade;
        let newArr = res.data.type.map((item) => ({
          label: item.line,
          value: item.line,
          children: item.part,
        }));
        newArr.forEach((item) => {
          item.children = item.children.map((item) => ({
            label: item,
            value: item,
          }));
        });
        this.syntaxType = newArr;
      });
    },
    //   日语APP语法列表
    getSyntaxList() {
      SyntaxList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.syntaxList = res.data.data;
      });
    },

    //阅读内容推荐到首页
    toIndex(id,is_recommend_index){
      if(is_recommend_index == 0){
        is_recommend_index = 1
      }else if(is_recommend_index == 1){
        is_recommend_index = 0
      }
      console.log(id,is_recommend_index)
      getReadContentRecommendIndex({id:id,recommend_index:is_recommend_index}).then((res)=>{
        console.log(res)
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.getContentList()
      })   
    },

    //文章分类弹窗
    toType(){
      this.typeDialog = true;
    },

    typeDialogClose(){

    },

    // 添加新增文章弹窗
    toAdd() {
      this.addForm = {
        article: {
          cover: "",
          cate_id: null,
          title: "",
          author: "",
          translation: "",
          content: "",
          words_id: null,
        },
        word: [{
          word: '',
          syntax: '',
          voice: "",
          note: "",
        }],
      };
      this.thumbSrc = "";
      this.isAdd = "add";
      this.addtitle = "新增文章";
      this.lineData = [];
      this.addDialog = true;
      this.isCascader = true;
    },
    // 添加新增分类弹窗
    toAddType() {
      this.addTypeTitle = "新增分类";
      this.addTypeDialog = true;
    },
    //   选择行段
    handleLineChange(value) {
      this.lineData = value;
      this.addForm.line = this.lineData[0];
      this.addForm.part = this.lineData[1];
    },
    //获取阅读内容列表
    getContentList(){
      // console.log(this.queryForm)
      getReadingContentList(this.queryForm).then((res)=>{
        res.data.data.forEach((items,indexs)=>{
          this.readingTypeList.forEach((item,index)=>{
            if(item.id == items.cate_id){
              items.cate_name = item.name
            }
          })
        })
        this.total = res.data.total;
        this.contentList = res.data.data
        // this.contentList.forEach((item,index)=>{
        //   if(!item.recommend_index){
        //     item.recommend_index = 0
        //   }
        // })
        console.log(this.contentList)
      })
    },
    // 添加文章
    addNow() {
      if (this.addForm.article.title == "") {
        this.$message.error("请输入文章标题");
        return;
      } else if (this.addForm.article.author == "") {
        this.$message.error("请输入文章作者");
        return;
      } else if (this.addForm.article.content == "") {
        this.$message.error("请输入文章内容");
        return;
      } else if (this.addForm.article.translation == "") {
        this.$message.error("请输入文章翻译");
        return;
      } else if (this.addForm.article.cate_id == null) {
        this.$message.error("请选择文章分类");
        return;
      }else if (this.thumbSrc == "") {
        this.$message.error("请输入封面");
        return;
      }
      this.addForm.word.forEach((item)=>{
        if(item.word == ""){
          this.$message.error("单词不能为空！");
          return;
        } else if(item.syntax == ""){
          this.$message.error("注释不能为空！");
          return;
        } else if(item.voice == ""){
          this.$message.error("单词音频不能为空！");
          return;
        }
        delete item.fileAudioName
        delete item.progress
      })
      let wordsValue = {
        word : this.addForm.word
      }
      console.log(wordsValue)
      addReadingContentWords(wordsValue).then((res) => {
        let wordidArr = res.data;
        wordidArr.forEach((item,index)=>{
          if(index == 0){
            this.addForm.article.words_id = item
          }else{
            this.addForm.article.words_id = this.addForm.article.words_id + "," + item
          }
        })
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        console.log(this.addForm.article)
        addReadingContent(this.addForm.article).then((res)=>{
          // console.log(res)
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("添加成功!");
          this.addDialog = false;
          this.getType();
          this.getContentList();
        })
      });
    },

    // 点击编辑图标
    editContent(item) {
      console.log(item)
      // let that = this;
      this.isAdd = "edit";
      this.addtitle = "编辑文章内容";
      this.addDialog = true;
      this.thumbSrc = item.cover;
      getReadingContentDetail({id:item.id}).then((res)=>{
        console.log(res)
        this.addForm.article = res.data;
        this.addForm.word = res.data.words_list;
        console.log(res.data.words_list)
        this.addForm.word.forEach((item)=>{
          delete item.id;
          delete item.create_time;
        })
        console.log(this.addForm.word)
        delete this.addForm.article.words_list;
        console.log(this.addForm.article)
      })
    },

    // 点击立即修改
    editReadNow() {
      this.addForm.word.forEach((item)=>{
        item.voice = item.voice
          .split("?")[0]
          .replace("https://file.qiaxueedu.com/", "");
        delete item.fileAudioName
        delete item.progress
      })
      if (this.addForm.article.title == "") {
        this.$message.error("请输入文章标题");
        return;
      } else if (this.addForm.article.author == "") {
        this.$message.error("请输入文章作者");
        return;
      } else if (this.addForm.article.content == "") {
        this.$message.error("请输入文章内容");
        return;
      } else if (this.addForm.article.translation == "") {
        this.$message.error("请输入文章翻译");
        return;
      } else if (this.addForm.article.cate_id == null) {
        this.$message.error("请选择文章分类");
        return;
      } else if (this.thumbSrc == "") {
        this.$message.error("请输入封面");
        return;
      } 
      this.addForm.word.forEach((item)=>{
        if(item.word == ""){
          this.$message.error("单词不能为空！");
          this.godown = false;
          return;
        } else if(item.syntax == ""){
          this.$message.error("注释不能为空！");
          this.godown = false;
          return;
        } else if(item.voice == ""){
          this.$message.error("单词音频不能为空！");
          this.godown = false;
          return;
        }
      })
      if(!this.godown){
        return;
      }
      let wordsValue = {
        word : this.addForm.word
      }
      console.log(wordsValue)
      addReadingContentWords(wordsValue).then((res) => {
        let wordidArr = res.data;
        wordidArr.forEach((item,index)=>{
          if(index == 0){
            this.addForm.article.words_id = item
          }else{
            this.addForm.article.words_id = this.addForm.article.words_id + "," + item
          }
        })
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        console.log(this.addForm.article)
        updateReadingContent(this.addForm.article).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("修改成功");
          this.addDialog = false;
          this.addDialog = false;
            this.getType();
            this.getContentList();
        });
      });
      this.addForm.article.word = this.addForm.word
      console.log(this.addForm.article)
    },

    // 弹窗关闭事件
    closed() {
      
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        // this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        // this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
        this.queryForm.start_time = this.times[0].getFullYear()+ '-' +(this.times[0].getMonth() + 1) + '-' + this.times[0].getDate() 
	      + ' ' + this.checkTime(this.times[0].getHours()) + ':' + this.checkTime(this.times[0].getMinutes()) + ':' + 
        this.checkTime(this.times[0].getSeconds());
        this.queryForm.end_time = this.times[1].getFullYear()+ '-' +(this.times[1].getMonth() + 1) + '-' + this.times[1].getDate() 
	      + ' ' + this.checkTime(this.times[1].getHours()) + ':' + this.checkTime(this.times[1].getMinutes()) + ':' + 
        this.checkTime(this.times[1].getSeconds());
      } else if (this.times == null) {
        this.queryForm.start_time = '';
        this.queryForm.end_time = '';
      }
      console.log(this.queryForm)
      this.getContentList();
    },
    //日期格式化
    checkTime(i) {//时间
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },
    handleSizeChange(size) {
      this.queryForm.size = size;
      this.getContentList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getContentList();
    },
    beforeThumbUpload(file) {
      let that = this;
      const is1M = file.size / 1024 / 1024 < 10;
      const isSize = new Promise(function (resolve, reject) {
        let width = 246; 
        let height = 336;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          getReadCosToken({ suffix: file.name }).then((res) => {
            var cos = new COS({
              getAuthorization: function (options, callback) {
                callback({
                  TmpSecretId: res.data.credentials.tmpSecretId,
                  TmpSecretKey: res.data.credentials.tmpSecretKey,
                  XCosSecurityToken: res.data.credentials.sessionToken,
                  StartTime: res.data.startTime,
                  ExpiredTime: res.data.expiredTime,
                  expiration: res.data.expiration,
                  requestId: res.data.requestId,
                });
              },
            });
            cos.putObject(
              {
                Bucket: res.data.Bucket,
                Region: res.data.Region,
                Key: res.data.key,
                // ContentType:'multipart/form-data',
                Body: file, // 上传文件对象
                onProgress: function (progressData) {},
              },
              function (err, data) {
                if (data) {
                  that.addForm.article.cover = res.data.key;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                    that.$forceUpdate();
                  };
                }
              }
            );
          });
          return file;
        },
        () => {
          that.$message.error(
            "上传封面图尺寸限制为246 x 336，大小不可超过10MB"
          );
          // return Promise.reject();
        }
      );
    },
    // 点击选择音频文件按钮
    selectAudio(index) {
      console.log(this.$refs.audio)
      console.log(index)
      this.$refs.audio[index].click();
      this.clickAudio = index;
    },

    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio[this.clickAudio].files[0];
      console.log(e)
      this.addForm.word[this.clickAudio].fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getReadCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.addForm.word[that.clickAudio].progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.word[that.clickAudio].voice = res.data.key;
              console.log(that.addForm)
            }
            e.target.value = "";
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}

li {
  list-style: none;
  cursor: pointer;
}
.navs {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  padding: 0 20px;
  .headers {
    border-bottom: 1px solid #eeeeee;
    height: 120px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    ul {
      width: 100%;
      li {
        height: 50px;
        float: left;
        margin-right: 60px;
      }
    }
    .selected {
      color: #38a28a;
      border-bottom: 1px solid #38a28a;
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
